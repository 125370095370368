<template>
  <v-row column>
    <v-col cols="12">
      <v-toolbar>
        <v-toolbar-title>
          Manage demo view
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-select
            class="select-user"
            label="select user"
            solo
            v-model="selectedUserDemo"
            :items="userDemo"
            color="primary"
          >

          </v-select>
          <v-btn text color="primary" @click="refresh()">
            refresh
          </v-btn>
          <v-btn text color="primary" @click="openCreateDemo()">
            create demo
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-dialog eager width="500px" v-model="openDemo">
        <v-card>
          <v-card-title
            class="headline grey lighten-2"
            primary-title>Create demo</v-card-title>
          <v-card-text>
            <v-text-field label="demo name" v-model="demoName">

            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="openDemo = false">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="isProcessingCreateDemo" @click="createDemo()">
              Create demo
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :search="selectedUserDemo"
        :items="demos"
        :headers="headers"
        :loading="isLoading"
        :footer-props="{
          itemsPerPageOptions: [20,50,100, {text: 'All', value: -1}]
        }"
      >
        <template v-slot:item="props">
          <tr>
            <td>{{ props.item.company ? props.item.company.id : errorDemoMessage }}</td>
            <td>{{ props.item.company ? props.item.company.name : errorDemoMessage }}</td>
            <td>{{ props.item.last_reset }}</td>
            <td>{{ props.item.created_by }}</td>
            <td>
              <v-btn v-if="props.item.company && props.item.company.id" text color="primary" target="_blank" rel="noopener noreferrer" :href="generateLinkToDemo(props.item.company.id)">
                go to demo
              </v-btn>
              <span v-else>{{ errorDemoMessage }}</span>
            </td>
            <td>
              <v-btn v-if="props.item.company && props.item.company.id" :loading="isProcessing" @click="resetDemo(props.item.company.id)">
                Reset demo
              </v-btn>
              <span v-else>{{ errorDemoMessage }}</span>
            </td>
            <td>
              <v-btn v-if="props.item.company && props.item.company.id" color="error" :loading="isProcessing" @click="deleteDemo(props.item.company.id)">
                Delete
              </v-btn>
              <span v-else>{{ errorDemoMessage }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { usersMixin } from '../mixins/usersMixin'
import store from '../../store'
import { common } from '../../store/modules/common'
import { baseViewMixin } from '@/mixins/baseViewMixin'

if (!store.state.common) store.registerModule('common', common)

export default {
  name: 'CuicuiDemoManager',
  mixins: [usersMixin, baseViewMixin],
  data: function () {
    return {
      demos: [],
      headers: [
        { text: 'Demo Id', value: 'company.id' },
        { text: 'Name', value: 'company.name' },
        { text: 'last reset', value: 'last_reset' },
        { text: 'created by', value: 'created_by' },
        { text: 'go to demo', value: '' },
        { text: 'reset', value: '' },
        { text: 'delete', value: '' }
      ],
      isLoading: false,
      isProcessing: false,
      isProcessingCreateDemo: false,
      openDemo: false,
      demoName: '',
      demoBaseUrl: 'https://demo.scibids.com/',
      selectedUserDemo: '',
      errorDemoMessage: 'ERROR WITH DEMO'
    }
  },
  mounted: async function () {
    this.onMounted()
    await this.$surcoucheConfig.refreshConfig()
    await this.callApiDemo()
    this.selectedUserDemo = this.$store.getters.getCurrentUserAvailableMail
  },
  methods: {
    async refresh () {
      await this.callApiDemo()
    },
    openCreateDemo () {
      this.openDemo = true
    },
    generateLinkToDemo (companyId) {
      return `${this.demoBaseUrl}overview?company_id=${companyId}`
    },
    async createDemo () {
      this.isProcessingCreateDemo = true
      const result = await this.$apiCaller.postCreateDemo(this.demoName)
      if (this.$apiCaller.isResponseError(result)) {
        console.warn('error when calling the demos')
        this.$emit('ask-snackbar', result.response.data.errors, 'error')
      } else {
        this.$emit('ask-snackbar', 'Success', 'success')
        await this.refresh()
      }
      this.isProcessingCreateDemo = false
      this.openDemo = false
      this.demoName = ''
    },
    async callApiDemo () {
      this.isLoading = true
      this.demos = []
      const result = await this.$apiCaller.getDemos()
      if (this.$apiCaller.isResponseError(result)) {
        console.warn('error when calling the demos')
        this.$store.commit('setErrorMessageWithResponse', result)
      } else {
        this.demos = result.data
      }
      this.isLoading = false
    },
    async resetDemo (demoId) {
      this.isProcessing = true
      const result = await this.$apiCaller.putResetDemo(demoId)
      if (this.$apiCaller.isResponseError(result)) {
        console.warn('error when reset the demos')
        this.$store.commit('setErrorMessageWithResponse', result)
      }
      this.isProcessing = false
      await this.refresh()
    },
    async deleteDemo (demoId) {
      this.isProcessing = true
      const result = await this.$apiCaller.deleteDemo(demoId)
      if (this.$apiCaller.isResponseError(result)) {
        console.warn('error when delete the demos')
        this.$store.commit('setErrorMessageWithResponse', result)
      }
      this.isProcessing = false
      await this.refresh()
    }
  },
  computed: {
    userDemo () {
      return [...this.demos.map((item) => {
        return { text: item.created_by, value: item.created_by }
      }), { text: 'All', value: '' }]
    }
  }
}
</script>

<style scoped>
.select-user {
  padding-top: 0.5em;
}
</style>
